import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I love carousels! The colorful theme park rides with animals and chariots are often beautifully crafted.
Kids can spend hours on them, going round and round. Pure joy!`}</p>
    <p>{`Can I say the same about carousel UI components? Not so much. I actually dislike most forms of web carousels,
especially when they change slides by themselves. Also, most of them are inaccessible nightmares. So, I said
to myself: Let's do better!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAD/9oADAMBAAIQAxAAAAF5RUH5TBS//8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAxES/9oACAEBAAEFAoSROzRorQ3h0f/EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPwFQf//EABURAQEAAAAAAAAAAAAAAAAAABBB/9oACAECAQE/Aaf/xAAZEAEAAgMAAAAAAAAAAAAAAAAAASEQETH/2gAIAQEABj8C3C1pcx//xAAaEAACAgMAAAAAAAAAAAAAAAABEQAhMVFh/9oACAEBAAE/ITNwDNw+AF4hCbX2LuXaiXAUJvU//9oADAMBAAIAAwAAABCvL//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QF0zl/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhMf/aAAgBAgEBPxBeC2//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFh8P/aAAgBAQABPxDsRtVpW7NcMEDfEDAa+Y9crqzUqaA5niwhoJsRqS/Az//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A carousel at night with bright lights. Figures of a horse and tiger are visible.",
          "title": "A carousel at night with bright lights. Figures of a horse and tiger are visible.",
          "src": "/static/6fdef0142ea1010d50437c1db21c7725/e5166/pexels-alexander-nadrilyanski-carousel.jpg",
          "srcSet": ["/static/6fdef0142ea1010d50437c1db21c7725/f93b5/pexels-alexander-nadrilyanski-carousel.jpg 300w", "/static/6fdef0142ea1010d50437c1db21c7725/b4294/pexels-alexander-nadrilyanski-carousel.jpg 600w", "/static/6fdef0142ea1010d50437c1db21c7725/e5166/pexels-alexander-nadrilyanski-carousel.jpg 1200w", "/static/6fdef0142ea1010d50437c1db21c7725/b17f8/pexels-alexander-nadrilyanski-carousel.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Alexander Nadrilyanski / pexels.com`}</em></p>
    <p>{`I've created a `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/accessible-news-carousel"
      }}>{`news carousel`}</a>{` as an
`}<a parentName="p" {...{
        "href": "https://angular.io/guide/standalone-components"
      }}>{`Angular standalone component`}</a>{`. It's accessible, responsive and
has smooth animations. Let's take a look at how it's built and how it works.`}</p>
    <h2>{`Demo: Accessible News Carousel`}</h2>
    <p>{`My `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/accessible-news-carousel/"
      }}>{`demo application`}</a>{` displays a list of news items,
each with a heading, text snippet and a background image. Users can move from slide to slide using swipe gestures
or the navigation buttons.`}</p>
    <iframe src="https://alexlehner86.github.io/accessible-news-carousel/" title="Demo Page: Accessible News Carousel" loading="lazy"></iframe>
    <h2>{`Design and Accessibility Requirements`}</h2>
    <p>{`There is no native HTML element for web carousels. That's why there are so many different custom implementations.
At least, the W3C has defined basic requirements in their “ARIA Authoring Practices Guide”. Their definition of
the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/carousel/"
      }}>{`carousel pattern`}</a>{` states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A `}<BoldText mdxType="BoldText">{`carousel`}</BoldText>{` presents a set of items, referred to as slides, by sequentially displaying a subset
of one or more slides. Typically, one slide is displayed at a time, and users can activate a next or previous slide
control that hides the current slide and “rotates” the next or previous slide into view.`}</p>
    </blockquote>
    <p>{`This abstract definition applies to basic image rotators as well as slide shows with complex content. In general,
carousels should fulfill the following requirements:`}</p>
    <ol>
      <li parentName="ol">{`It should convey its structure to assistive technologies.`}</li>
      <li parentName="ol">{`It should indicate the currently active slide, both visually and to assistive technologies.`}</li>
      <li parentName="ol">{`Users should `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/pointer-gestures.html"
        }}>{`not be forced to use swipe gestures`}</a>{`
to operate the carousel. It should offer simple controls that the user can click on or operate with the keyboard.`}</li>
      <li parentName="ol">{`Dynamic content changes of the carousel (e.g. moving to the next slide) should be communicated to screen reader users
via `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/status-messages.html"
        }}>{`status messages`}</a>{`.`}</li>
      <li parentName="ol">{`If the carousel can automatically rotate, it also has a button for stopping and restarting rotation.`}</li>
    </ol>
    <p>{`I hate moving content that starts automatically. That's why my news carousel doesn't rotate on its own and, therefore,
has no pause button.`}</p>
    <h2>{`Content Structure and Semantic Markup`}</h2>
    <h3>{`The Carousel Container`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.2/"
      }}>{`ARIA specification`}</a>{` doesn't include a `}<InlineCode mdxType="InlineCode">{`carousel`}</InlineCode>{` role.
Therefore, you need to set the generic `}<InlineCode mdxType="InlineCode">{`role="region"`}</InlineCode>{` and provide a custom description
with `}<InlineCode mdxType="InlineCode">{`aria-roledescription="carousel"`}</InlineCode>{`. An accessible label is provided
by the `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` attribute.`}</p>
    <p>{`In my `}<InlineCode mdxType="InlineCode">{`NewsCarouselComponent`}</InlineCode>{`, I use the `}<a parentName="p" {...{
        "href": "https://angular.io/api/core/HostBinding"
      }}>{`HostBinding`}</a>{` decorator to apply these
properties to the carousel component's container:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/**
 * The aria label for the carousel container.
 * It should convey to screenreader users what the carousel is about.
 */
@HostBinding('attr.aria-label') @Input() public carouselLabel!: string;
@HostBinding('attr.role') role = 'region';
@HostBinding('attr.aria-roledescription') get carouselDescription() { return this.config.carouselDescription; }`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The carousel includes two icon buttons to navigate to the previous or next slide. Accessible labels are provided by
the `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` attribute set on the `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` tags.`}</p>
    <h3>{`The Slide Container`}</h3>
    <p>{`Each slide container has `}<InlineCode mdxType="InlineCode">{`role="group"`}</InlineCode>{` and the property `}<InlineCode mdxType="InlineCode">{`aria-roledescription`}</InlineCode>{`
set to “slide”. The slide container's `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` attribute conveys the slide's number and the total
set size (e.g., “2 of 5”).`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div
    *ngFor="let item of newsItems; let i = index"
    class="news-item"
    role="group"
    [attr.aria-roledescription]="config.slideDescription"
    [attr.aria-label]="(i + 1) + ' ' + config.slideLabel + ' ' + newsItems.length"
>
    <!-- slide content -->
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The disadvantage of custom role descriptions is that they're not automatically translated by screen readers. If you're
building a multi-lingual website, you'll need to keep that in mind. My `}<InlineCode mdxType="InlineCode">{`NewsCarouselComponent`}</InlineCode>{`
has a `}<InlineCode mdxType="InlineCode">{`config`}</InlineCode>{` input property with default values in English that can be overriden.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@Input() public config: NewsCarouselConfig = {
    carouselDescription: 'carousel',
    slideDescription: 'slide',
    slideLabel: 'of',
    nextButtonLabel: 'Next slide',
    previousButtonLabel: 'Previous slide',
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Avoid List Markup`}</h3>
    <p>{`A common mistake is to use list markup for the slides. Screen readers announce the number of items in a list, but ignore
list items that are hidden. In my component, only the visible slide is also presented to assistive technologies. The other
slides are visually and programmatically hidden using the CSS property `}<InlineCode mdxType="InlineCode">{`visibility: hidden`}</InlineCode>{`.`}</p>
    <p>{`If I'd implement all slides as `}<InlineCode mdxType="InlineCode">{`<`}{`li`}{`>`}</InlineCode>{` elements inside an unordered
list (`}<InlineCode mdxType="InlineCode">{`<`}{`ul`}{`>`}</InlineCode>{`), then the screen reader would announce the presence of a list with only one item.
This would not match the true number of slides and be confusing for the user.`}</p>
    <h3>{`ARIA Live Region`}</h3>
    <p>{`The carousel should communicate dynamic content changes to screen reader users via status messages.
At first, I tried to turn the container element for all slides into an
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions"
      }}>{`ARIA live region`}</a>{`. The results were not
satisfying. Due to the implementation of the slide transition using the `}<InlineCode mdxType="InlineCode">{`visibility`}</InlineCode>{` CSS property,
the screen reader would announce the new slide several times or announce the wrong slide.`}</p>
    <p>{`I tried different approaches using properties like `}<InlineCode mdxType="InlineCode">{`aria-busy`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`aria-relevant`}</InlineCode>{`.
They only ever worked for some browser and screen reader combinations. So, in the end I chose the simple approach of putting
a `}<a parentName="p" {...{
        "href": "https://webaim.org/techniques/css/invisiblecontent/"
      }}>{`visually hidden`}</a>{` ARIA live region at the end of the carousel container.
It's a bit redundant, but it works!`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div
    class="active-slide-live-region"
    aria-live="polite"
>
    {{config.slideDescription + ' ' + (activeSlideIndex + 1) + ' ' + config.slideLabel + ' ' + newsItems.length + ':'}}
    {{newsItems[activeSlideIndex].heading}}
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Keyboard Operability and Swipe Gestures`}</h2>
    <p>{`I love swiping! When I come across a carousel on a website, I instinctively try to swipe its content. Swiping itself is
not evil. But a web carousel that is only operable through swipe gestures is!`}</p>
    <p>{`Swipe gestures are inaccessible to many people with motor disabilities. They prefer
`}<a parentName="p" {...{
        "href": "https://www.accessguide.io/guide/single-pointer-gestures"
      }}>{`single-pointer alternatives`}</a>{` like a clickable button. Also,
keyboard users require controls they can tab to and activate with the space or enter key.`}</p>
    <p>{`This is why I try to make everyone happy! My Angular component implements swipe gestures using
the `}<a parentName="p" {...{
        "href": "https://hammerjs.github.io/"
      }}>{`Hammer.js library`}</a>{` and also includes buttons to navigate to the previous or next slide.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div class="news-carousel-controls">
    <button
        type="button"
        [attr.aria-label]="config.previousButtonLabel"
        (click)="this.showPreviousSlide()"
    >
        <!-- arrow icon -->
    </button>
    <button
        type="button"
        [attr.aria-label]="config.nextButtonLabel"
        (click)="this.showNextSlide()"
    >
        <!-- arrow icon -->
    </button>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Slide Transitions with CSS Animations`}</h2>
    <p>{`I wanted to create beautiful transitions between slides with smooth slide in and out animations. Angular provides
its own `}<a parentName="p" {...{
        "href": "https://angular.io/guide/animations"
      }}>{`animations module`}</a>{`. But I wanted to find a solution that would reduce
the build size and be efficient at the same time. Which is why I decided to use
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Using_CSS_animations"
      }}>{`CSS animations`}</a>{`.`}</p>
    <p>{`Creating animations with CSS is super simple and elegant. First, you define keyframes for your named animations.
Each keyframe describes how the animated element should render at a given time during the animation sequence.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`// Keyframes for slide animation from left to right
@keyframes slide-in-from-left {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out-to-right {
    0% {
        transform: translateX(0%);
        visibility: visible;
    }
    100% {
        transform: translateX(100%);
        visibility: hidden;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Then, you apply these animations to specific slides using the `}<InlineCode mdxType="InlineCode">{`animation`}</InlineCode>{` CSS property. This shorthand
allows you to define a wide range of animation related properties (e.g., name, duration, direction) in a single CSS rule.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`app-news-carousel .news-carousel-items.animate-from-left .news-item {
    &.active {
        animation: slide-in-from-left 0.5s forwards;
    }

    &.moved-out {
        animation: slide-out-to-right 0.5s forwards;
        border-left: 0.125rem solid white;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In the example above, two HTML elements are animated: The element with the class `}<InlineCode mdxType="InlineCode">{`active`}</InlineCode>{` slides
in from the left while the other element with the class `}<InlineCode mdxType="InlineCode">{`moved-out`}</InlineCode>{` slides out to the right.`}</p>
    <h2>{`Responsive Design and Custom Styling`}</h2>
    <h3>{`Flexible Layout with `}<InlineCode mdxType="InlineCode">{`max-width`}</InlineCode>{` and Container Queries`}</h3>
    <p>{`An application that uses the carousel component can set the CSS variable `}<InlineCode mdxType="InlineCode">{`--news-carousel-max-width`}</InlineCode>{`
to define a basic width for the carousel container. If no variable is set, then the fallback value of
the `}<InlineCode mdxType="InlineCode">{`var() function`}</InlineCode>{` is used.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`app-news-carousel {
    height: var(--news-carousel-height, 25rem);
    width: var(--news-carousel-max-width, 50rem);
    max-width: 100%;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`By setting `}<InlineCode mdxType="InlineCode">{`max-width: 100%`}</InlineCode>{` we ensure that the containers width is limited by the space available
to its parent element. This way, the carousel never uses up more space than the viewport width of the device.`}</p>
    <p>{`Furthermore, I use `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries"
      }}>{`CSS Container Queries`}</a>{`
to adapt the carousel's styling if the container's width is below a certain threshold. This new, powerful feature will soon
be `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-container-queries"
      }}>{`supported by all major browsers`}</a>{`. I'll take a closer look at it in a
separate blog post.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@container news-carousel (max-width: 28rem) {
    app-news-carousel .news-item .slide-text {
        bottom: 0.5rem;
        border-radius: 0.5rem;
        width: calc(100% - 1rem);

        p {
            display: none;
        }
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Custom Styling with CSS Variables`}</h3>
    <p>{`CSS variables are great for component libraries. You can define a default styling and allow the user to override specific
styles by defining the responding variable. An application using my `}<InlineCode mdxType="InlineCode">{`NewsCarouselComponent`}</InlineCode>{`
can customize the following style properties via CSS variables:`}</p>
    <ul>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-height</InlineCode>: Set the height of the carousel.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-max-width</InlineCode>: Set the max width of the carousel. The carousel's actual width is responsive to its container.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-button-background</InlineCode>: The background (color) of the previous and next buttons.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-button-color</InlineCode>: The color of the arrow icon in the previous and next buttons.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--carousel-text-background</InlineCode>: The background (color) of the slides' text container.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--carousel-text-color</InlineCode>: The text color of the slides' text container.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-slide-focus-color</InlineCode>: The color of the slides' focus indicator.
      </li>
    </ul>
    <h2>{`What Else to Say`}</h2>
    <p>{`I want to make one thing clear: My carousel component is only one of several ways to implement an accessible, responsive
carousel. Yes, I'm pretty confident that my implementation is awesome. But of course, there's other valid approaches as well.`}</p>
    <p>{`Web standards, browsers and assistive technologies are always evolving and changing. So, in a few years, or maybe even in a
few months, there might be a better solution. I can't wait to see it! 😊`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/"
        }}>{`ARIA Authoring Practices Guide (APG)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-1.2/"
        }}>{`ARIA 1.2 specification`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://angular.io/guide/standalone-components"
        }}>{`Angular standalone components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Using_CSS_animations"
        }}>{`CSS Animations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries"
        }}>{`CSS Container Queries`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      